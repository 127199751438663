<template>
    <div>
        <section v-if="styles.length>0">
            <div class="grid md:grid-9-3 ">
                <div class="h-65vh position-relative">
                    <transition name="fade" v-for="(style,i) in styles">
                        <div
                            style="background-size: cover; overflow: hidden; position: absolute; z-index:1; top:0; background-position: center center"
                            :style="{backgroundImage:'url('+store.state.exterior.productImage+')'}"
                            v-if="store.state.exterior.id===style.id" class="w-100p h-100p">
                        </div>
                    </transition>
                </div>
                <div class="m-l-2 p-3 d-flex f-column  md:h-65vh">
                    <selection-header back-to="/" :steps="6" :current-step="2"/>
                    <total-runner :step="2" class="f-1"/>
                    <bottom-nav to="/interior" back-to="/"/>
                </div>
            </div>
        </section>
        <div v-if="styles.length>0 && selected && selected.id">

            <tab-shop :item="selected" :show-inclusive="true" :multi-items="{style:styles}" selected-tab="style"
                      image-property="farbton" @update="selected = $event.item">Select your exterior
            </tab-shop>
        </div>
    </div>

</template>

<script setup>
import {useStore} from 'vuex'
import TabShop from "@/components/TabShop"
import BottomNav from "@/components/BottomNav";
import SelectionHeader from "@/components/selectionHeader.vue";
import TotalRunner from "@/components/totalRunner.vue";
import {ref, watch} from "vue";

const store = useStore();
const styles = ref([]);
const selected = ref(store.state.exterior);
watch(selected, newVal => {
    if (newVal) {
        store.commit('setExterior', newVal)
    }
})

styles.value = store.getters.exterior.sort((a, b) => a.price - b.price);

store.commit('setExterior', {...selected.value})


</script>

<style scoped>

</style>